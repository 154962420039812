import React from "react";
import routes from "routes";
import DefaultFooter from "components/Footers/DefaultFooter";
import footerRoutes from "footer.routes";

import Menu from "components/Menu";

import { Card, Container } from "@mui/material";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import LogoMPO from "assets/images/logo_horizontal.png";

export default function Privacy() {
  const authenticatedUser = JSON.parse(localStorage.getItem("user")) ?? false;
  const routeIndex = authenticatedUser.type ?? "public";

  return (
    <>
      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />
      <Container>
        <MKBox component="section" pt={20} pb={12}>
          <Card>
            <MKBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="success"
              mx={2}
              mt={-3}
              py={1}
              mb={1}
              textAlign="center"
            >
              <MKTypography variant="body1" color="white" mt={0}>
                Política de Privacidade e Termos de Uso
              </MKTypography>
              <MKTypography variant="body2" color="white" opacity={0.8}>
                Última modificação em 27/062024
              </MKTypography>
            </MKBox>
            <MKBox pt={2} px={6}>
              <MKBox pt={2} px={6}>
                <MKTypography
                  variant="body2"
                  color="text"
                  textAlign="justify"
                  ml={2}
                >
                  Agradecemos por acessar a plataforma MPO SAÚDE.
                </MKTypography>
                <MKTypography
                  variant="body2"
                  color="text"
                  textAlign="justify"
                  ml={2}
                >
                  Nós somos responsáveis por fornecer aos Pacientes a
                  possibilidade de se conectarem a Profissionais de Saúde para a
                  r ealização de atendimentos na área de Psiquiatria,
                  endocrinologia, cardiologia, ginecologia, pediatria e
                  obstetrícia.
                </MKTypography>
              </MKBox>
              <MKBox pb={6} pt={2} px={6}>
                <MKTypography
                  variant="body2"
                  color="text"
                  textAlign="justify"
                  ml={2}
                >
                  <ol>
                    <li>
                      <h3>Termos Gerais</h3>
                      <MKTypography
                        variant="body2"
                        color="text"
                        textAlign="justify"
                        ml={2}
                      >
                        <ol>
                          <li>
                            <b>Geral:</b> A MPO SAÚDE, inscrita no CNPJ sob o no
                            55.019.118/0001-53, considera que a relação entre os
                            Pacientes e Profissionais de Saúde, bem como que o
                            uso de dados pessoais, são assuntos de extrema
                            importância e seriedade. Por esse motivo, esta
                            Política de Privacidade foi elaborada para comunicar
                            as práticas relativas ao tratamento de dados
                            pessoais e dados pessoais sensíveis, especialmente
                            relacionados à coleta, utilização e processamento de
                            informações que os Pacientes e Profissionais de
                            Saúde forneçam através da plataforma.
                          </li>
                          <li>
                            <b>O que são Dados Pessoais:</b> Os dados pessoais
                            são as informações que identificam ou permitem
                            identificar as pessoas naturais, como Pacientes e
                            Profissionais de Saúde.
                          </li>
                          <li>
                            <b>O que são Dados Pessoais Sensíveis:</b> São dados
                            pessoais sobre origem racial ou étnica, convicção
                            religiosa, opinião política, filiação a sindicato ou
                            a organização de caráter religioso, filosófico ou
                            político, dados referentes à saúde ou à vida sexual,
                            dados genéticos ou biométricos, os quais são
                            vinculados a uma pessoa natural.
                          </li>
                          <li>
                            <b>Autorização de Coleta e Processamento:</b> Ao
                            utilizar a Plataforma MPO SAÚDE, você, Paciente ou
                            Profissional de Saúde,
                          </li>
                          <li>
                            <b>Regulamentação:</b> A Plataforma e os
                            Profissionais de Saúde, quando aplicáveis, atuam de
                            acordo com a legislação brasileira, atendendo,
                            inclusive e especialmente, os termos do Código de
                            Defesa do Consumidor (Lei no 8.078/1990), Lei no.
                            12.965/2014 (o Marco Civil da Internet), Lei no
                            13.709/2018 (Lei Geral de Proteção de Dados
                            Pessoais), da Lei no 13.787/2018 (Lei do Prontuário
                            Eletrônico), da Resolução CFM no 2.314/2022,
                            Resolução CFM no 2.217/2018 (Código de Ética
                            Médica), Resolução CFP no 11/2018, Resolução CFP no
                            10/2005 (Código de Ética Profissional do Psicólogo),
                            e demais normas que venham a ser aplicadas.
                          </li>
                          <li>
                            <b>Contato:</b> Em caso de dúvida ou sugestões sobre
                            a Política de Privacidade da plataforma, o Paciente
                            ou Profissional de Saúde poderá entrar em contato
                            com os administradores pelo e-mail
                          </li>
                          <li>
                            <b>Controle de Informações:</b> Esta Política
                            descreve quais os dados pessoais podem ser coletados
                            dos Pacientes e os Profissionais de Saúde, enquanto
                            utilizam os serviços da plataforma, como essas
                            informações podem ser utilizadas e as precauções
                            tomadas contra o acesso ou uso destas informações de
                            forma não autorizada.
                          </li>
                        </ol>
                      </MKTypography>
                    </li>
                    <li>
                      <h3>Dados Pessoais e Sensíveis Coletados</h3>
                      <MKTypography
                        variant="body2"
                        color="text"
                        textAlign="justify"
                        ml={2}
                      >
                        <ol>
                          <li>
                            <b>Coleta de Dados dos Pacientes:</b> A MPO SAÚDE
                            poderá coletar, armazenar e utilizar as seguintes
                            informações e/ou dados pessoais e dados pessoais
                            sensíveis dos Pacientes:
                            <MKTypography
                              variant="body2"
                              color="text"
                              textAlign="justify"
                              ml={2}
                            >
                              <ul>
                                <li>Nome completo;</li>
                                <li>Idade;</li>
                                <li>Telefone;</li>
                                <li>Endereço de e-mail válido;</li>
                                <li>Data de nascimento;</li>
                                <li>Sexo;</li>
                                <li>CPF;</li>
                                <li>RG/Passaporte;</li>
                                <li>Filiação;</li>
                                <li>Fotos;</li>
                                <li>Imagens;</li>
                                <li>Áudios;</li>
                                <li>Peso;</li>
                                <li>Altura;</li>
                                <li>
                                  Existência de Planos de Saúde conveniados;
                                </li>
                                <li>
                                  Informações adicionais de saúde (alergias,
                                  medicamentos, condições específicas);
                                </li>
                                <li>Exames médicos;</li>
                                <li>
                                  Documentos de representação legal, em caso de
                                  cadastro de dependentes absolutamente ou
                                  relativamente incapazes;
                                </li>
                                <li>
                                  Vídeo de gravação da consulta, apenas nos
                                  casos em que esta função estiver habilitada.
                                </li>
                              </ul>
                            </MKTypography>
                          </li>
                          <li>
                            <b>Coleta de Dados dos Profissionais de Saúde:</b> A
                            MPO SAÚDE poderá coletar e utilizar as seguintes
                            informações e dados dos Profissionais de Saúde:
                            <MKTypography
                              variant="body2"
                              color="text"
                              textAlign="justify"
                              ml={2}
                            >
                              <ul>
                                <li>Nome completo;</li>
                                <li>CPF;</li>
                                <li>Telefone;</li>
                                <li>Endereço de e-mail válido;</li>
                                <li>Endereço;</li>
                                <li>Imagens;</li>
                                <li>Áudios;</li>
                                <li>
                                  Inscrição válida no conselho de representação
                                  profissional respectivo (CFM, CFP, COFEN, CFN,
                                  CFFa);
                                </li>
                                <li>Descrição do currículo;</li>
                                <li>
                                  Vídeo de gravação da consulta, apenas nos
                                  casos em que esta função estiver habilitada;
                                </li>
                                <li>
                                  Assinatura via certificado digital ICP/Brasil.
                                </li>
                              </ul>
                            </MKTypography>
                          </li>
                          <li>
                            <b>Finalidade da Coleta de Dados:</b> Os dados
                            pessoais coletados pela MPO SAÚDE estão enquadrados
                            nas seguintes finalidades e bases legais:
                            <table border={1} style={{ margin: 2 }}>
                              <tr>
                                <th style={{ padding: 2, textAlign: "center" }}>
                                  Dado Coletado
                                </th>
                                <th style={{ padding: 2, textAlign: "center" }}>
                                  Finalidade
                                </th>
                                <th style={{ padding: 2, textAlign: "center" }}>
                                  Base Legal
                                </th>
                              </tr>
                              <tr>
                                <td style={{ padding: 10 }}>
                                  Nome completo, CPF, RG/Passaporte, filiação,
                                  endereço, inscrição válida no conselho de
                                  representação profissional respectivo,
                                  descrição do currículo, gênero e foto.
                                </td>
                                <td style={{ padding: 10 }}>
                                  Identificar Pacientes e Profissionais de
                                  Saúde;
                                </td>
                                <td style={{ padding: 10 }}>
                                  Profissional de Saúde: execução de contrato
                                  (art. 7o, V) - Paciente: consentimento (art.
                                  7o, I)
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: 10 }}>
                                  Idade, sexo, peso, altura, existência de plano
                                  de saúde, exames médicos e informações
                                  adicionais desaúde
                                </td>
                                <td style={{ padding: 10 }}>
                                  Prestação de serviços detele saúde aos
                                  Pacientes
                                </td>
                                <td style={{ padding: 10 }}>
                                  Tutela da saúde (art. 7o, VIII)
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: 10 }}>E-mail</td>
                                <td style={{ padding: 10 }}>
                                  Enviar agendamentos de atendimentos, lembretes
                                  de consultas e demais comunicações com os
                                  Pacientes e Profissionais de Saúde
                                  (informativos, comunicações, etc.)
                                </td>
                                <td style={{ padding: 10 }}>
                                  Consentimento (art. 7o, I)
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: 10 }}>Telefone</td>
                                <td style={{ padding: 10 }}>
                                  Enviar lembretes de agendamentos de
                                  atendimentos ou para entrar em contato com o
                                  Paciente e Profissionais de Saúde quando
                                  necessário (suporte, orientação, comunicações
                                  relativas à operação da Plataforma, etc.)
                                </td>
                                <td style={{ padding: 10 }}>
                                  Consentimento (art. 7o, I)
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: 10 }}>
                                  Registro Documental
                                </td>
                                <td style={{ padding: 10 }}>
                                  Utilizados para fins de segurança, auditoria e
                                  eventual compartilhamento com o Paciente e/ou
                                  com o Conselho Profissional respectivo para
                                  orientação, fiscalização e serventia de prova
                                  idônea para instruir processo disciplinar.
                                </td>
                                <td style={{ padding: 10 }}>
                                  Cumprimento de obrigação legal ou regulatória
                                  (art. 7o, II)
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: 10 }}>
                                  Logs de Navegação
                                </td>
                                <td style={{ padding: 10 }}>
                                  Utilizados para fins de segurança e auditoria,
                                  compartilhamento com autoridades judiciais,
                                  administrativas ou governamentais, sempre que
                                  houver requerimento, requisição ou ordem
                                  judicial
                                </td>
                                <td style={{ padding: 10 }}>
                                  Cumprimento de obrigação legal ou regulatória
                                  (art. 7o, II)
                                </td>
                              </tr>
                            </table>
                          </li>
                          <li>
                            <b>Dados de utilização:</b> A MPO SAÚDE também
                            poderá coletar, armazenar e utilizar os seguintes
                            dados pessoais classificados como “dados de
                            utilização”:
                            <MKTypography
                              variant="body2"
                              color="text"
                              textAlign="justify"
                              ml={2}
                            >
                              <ul>
                                <li>
                                  Registro de qualquer comunicação, como envio
                                  de arquivos ou informações carregadas na
                                  plataforma, realizadas entre Pacientes e
                                  Profissionais de Saúde;
                                </li>
                                <li>
                                  Os detalhes das visitas à Plataforma e dos
                                  recursos que o Paciente ou Profissional de
                                  Saúde acessou;
                                </li>
                                <li>
                                  Informações do dispositivo de acesso
                                  utilizado, incluindo, por exemplo, modelo do
                                  hardware, sistema operacional e versão, nomes
                                  e versões dos arquivos, idioma de preferência,
                                  identificador exclusivo do dispositivo,
                                  identificadores de publicidade, número de
                                  série, informações referentes ao movimento do
                                  dispositivo e informações de rede;
                                </li>
                                <li>
                                  Informações de log de acesso (que incluem IP
                                  do dispositivo, data e hora) do Paciente ou
                                  Profissional de Saúde, características da
                                  navegação, tipo de navegador e meio de
                                  navegação;
                                </li>
                                <li>
                                  Mapeamento de cliques no navegador, dados de
                                  navegação, estatísticos, demográficos, entre
                                  outros;
                                </li>
                                <li>
                                  Dados preferenciais sobre como o Paciente ou
                                  Profissional de Saúde interage com os
                                  serviços, as preferências manifestadas e as
                                  configurações escolhidas. Em alguns casos,
                                  para tanto, faz-se o uso de cookies, pixel
                                  tags e tecnologias similares que criam e
                                  mantêm identificadores exclusivos.
                                </li>
                              </ul>
                            </MKTypography>
                          </li>
                        </ol>
                      </MKTypography>
                    </li>
                    <li>
                      <h3>Como Utilizamos os Dados Coletados</h3>
                      <MKTypography
                        variant="body2"
                        color="text"
                        textAlign="justify"
                        ml={2}
                      >
                        <ol>
                          <li>
                            <b>Utilização:</b> Ao consentir com a presente
                            política, os Pacientes e Profissionais de Saúde
                            concordam que a MPO SAÚDE poderá realizar o
                            tratamento dos dados coletados pela Plataforma, com
                            o objetivo de:
                            <MKTypography
                              variant="body2"
                              color="text"
                              textAlign="justify"
                              ml={2}
                            >
                              <ul>
                                <li>
                                  Realizar a identificação e cadastro dos
                                  Pacientes e Profissionais de Saúde na
                                  Plataforma e disponibilizar os serviços;
                                </li>
                                <li>
                                  Garantir que o conteúdo da plataforma seja
                                  apresentado da forma mais eficiente para o
                                  Paciente ou Profissional de Saúde;
                                </li>
                                <li>
                                  Ajudar a realizar melhorias de caráter geral
                                  na plataforma;
                                </li>
                                <li>
                                  Processar os dados colhidos por diferentes
                                  algoritmos internos do serviço ou de
                                  terceiros;
                                </li>
                                <li>
                                  Realizar e apoiar pesquisas científicas em
                                  prol da promoção de estudos em saúde pública,
                                  por meio do uso de dados anonimizados e desde
                                  que respeitados os devidos padrões éticos
                                  relacionados a esses estudos e pesquisas;
                                </li>
                                <li>
                                  Contatar e notificar o Paciente ou
                                  Profissional de Saúde acerca de modificações
                                  na plataforma, serviços oferecidos pela MPO
                                  SAÚDE ou em suas políticas e termos de uso,
                                  quando necessário;
                                </li>
                                <li>
                                  Analisar as informações fornecidas a fim de
                                  garantir uma prestação de serviços efetiva
                                  pelo Profissional de Saúde;
                                </li>
                                <li>
                                  Processar os dados com o fito de auxiliar no
                                  controle do absenteísmo nas empresas;
                                </li>
                                <li>
                                  Enviar newsletters e correios informativos;
                                </li>
                                <li>
                                  Realizar campanhas de marketing e enviar
                                  publicidade através dos meios de contato
                                  informados;
                                </li>
                                <li>
                                  Realizar cobranças sobre os serviços
                                  prestados, quando aplicável.
                                </li>
                              </ul>
                            </MKTypography>
                          </li>
                        </ol>
                      </MKTypography>
                    </li>
                    <li>
                      <h3>Compartilhamento dos Dados Coletados</h3>
                      <p>
                        A MPO SAÚDE não divulga a terceiros qualquer informação
                        fornecida pelo Paciente ou Profissional de Saúde através
                        da plataforma, exceto:
                      </p>
                      <MKTypography
                        variant="body2"
                        color="text"
                        textAlign="justify"
                        ml={2}
                      >
                        <ol>
                          <li>
                            <b>Exceções para o Compartilhamento dos Dados:</b>
                            <MKTypography
                              variant="body2"
                              color="text"
                              textAlign="justify"
                              ml={2}
                            >
                              <ul>
                                <li>
                                  nos casos em que a MPO SAÚDE for obrigada a
                                  divulgar ou partilhar os dados coletados, a
                                  fim de cumprir qualquer obrigação legal (como
                                  em virtude de ordem judicial), ou para efeitos
                                  de prevenção de fraude ou outros crimes, bem
                                  como em resposta à solicitação de informações
                                  apresentada por autoridade competente, se
                                  entendermos que a divulgação está de acordo ou
                                  é exigida conforme as leis, regulamentos ou
                                  procedimentos processuais aplicáveis;
                                </li>
                                <li>
                                  para proteger os direitos, bens ou a segurança
                                  da MPO SAÚDE e da plataforma;
                                </li>
                                <li>
                                  com a Plataforma MEU PSIQUIATRA ONLINE quando
                                  o paciente ou profissional de saúde tenha
                                  acesso a ambas as plataformas, para fins de
                                  integração de informações.
                                </li>
                                <li>
                                  com fornecedores, consultores, parceiros de
                                  marketing, prestadores de serviços de business
                                  intelligence e data analytics e outros
                                  prestadores deserviços que precisem acessar as
                                  informações para execução do trabalho emnosso
                                  nome, estando estes sujeitos a seguir as
                                  mesmas regras dispostas nesta política;
                                </li>
                                <li>
                                  com órgãos públicos de fiscalização e
                                  controle, se entendermos que suas ações são
                                  inconsistentes em relação às disposições em
                                  nossos termos de uso, ou para proteger os
                                  direitos, a propriedade ou a segurança da MPO
                                  SAÚDE, de seus Pacientes e Profissionais de
                                  Saúde, ou de terceiros;
                                </li>
                                <li>
                                  se, mediante notificação prévia, você
                                  concordar em compartilhá-las;
                                </li>
                                <li>
                                  mediante ação própria do Paciente ou
                                  Profissional de Saúde;
                                </li>
                                <li>
                                  nos casos de venda, parcial ou total, do
                                  negócio ou de seus ativos, ou como parte de
                                  qualquer reorganização ou reestruturação do
                                  negócio, fusão, cisão ou incorporação, de modo
                                  que a MPO SAÚDE poderá compartilharas
                                  informações dos Pacientes e Profissionais de
                                  Saúde com terceiros que façam parte dos
                                  negócios, tomando as medidas necessárias para
                                  garantir que os direitos de privacidade
                                  continuem a ser protegidos, conforme esta
                                  política;
                                </li>
                                <li>
                                  com Profissionais de Saúde contatados pelo
                                  Paciente para oferecer serviços de telessaúde,
                                  somente com relação às informações relevantes
                                  paratal prestação de serviço;
                                </li>
                                <li>
                                  com terceiros, tais como afiliadas,
                                  subcontratados e/ou parceiros, os relatórios
                                  estatísticos criados a partir de dados
                                  anonimizados, que não revelem a identidade dos
                                  titulares;
                                </li>
                                <li>
                                  com os proprietários/desenvolvedores da
                                  plataforma, tão somente para garantir o
                                  desenvolvimento e continuidade da plataforma,
                                  com correções de eventuais erros e/ou falhas
                                  técnicas.
                                </li>
                              </ul>
                            </MKTypography>
                          </li>
                          <li>
                            <b>Autorizações de Contato e Divulgação:</b>
                          </li>
                          <p>
                            Os Pacientes e Profissionais de Saúde autorizam a
                            empresa MPO SAÚDE a contatá-los utilizando qualquer
                            um dos meios de contato informados no cadastro.
                          </p>
                          <p>
                            Parágrafo Único: É de inteira reponsabilidade do
                            médico o envio correto dos documentos para os
                            paciente, incluindo relatórios, laudos, solicitação
                            de exames e receitas.
                          </p>
                        </ol>
                      </MKTypography>
                    </li>
                    <li>
                      <h3>Cookies</h3>
                      <MKTypography
                        variant="body2"
                        color="text"
                        textAlign="justify"
                        ml={2}
                      >
                        <ol>
                          <li>
                            <b>O que são os Cookies:</b>
                            <p>
                              Um cookie é um trecho de informação armazenado
                              localmente no computador ou dispositivo do
                              Paciente ou Profissional de Saúde, e que contém
                              informação acerca das atividades deste na
                              Internet.
                            </p>
                          </li>
                          <li>
                            <b>Cookies Utilizados:</b>
                            <p>
                              Os cookies podem ser usados de diferentes formas e
                              modalidades. A Plataforma MPO SAÚDE poderá
                              utilizar:
                            </p>
                            <ul>
                              <li>
                                Cookies Funcionais. Estes cookies são usados
                                pelo nosso website poralgumas de suas
                                funcionalidades, como por exemplo, para sabermos
                                o número de Pacientes ativos e sua retenção. Com
                                isso, podemos fazer adaptações de forma a
                                melhorar a experiência do Paciente.
                              </li>
                              <li>
                                Cookies de desempenho. Este tipo de cookie
                                coleta informações anônimas sobre a forma como
                                os nossos Pacientes utilizam o site, para
                                podermos otimizá-lo. Por exemplo, cookie que
                                permite saber se você escolheu a opção
                                “Mantenha-me conectado” para que o site entre
                                automaticamente na conta da próxima vez que
                                voltar à página. As informações coletadas por
                                estes cookies nunca contêm detalhes pessoais a
                                partir dos quais seria possível identificá-lo.
                              </li>
                              <li>
                                Cookies de sessão. São os Cookies temporários
                                que permanecem arquivados até que você saia do
                                site ou encerre o navegador.
                              </li>
                              <li>
                                Cookies persistentes. São os Cookies que ficam
                                armazenados no seu dispositivo até que sejam
                                excluídos (o tempo que o Cookie permanecerá no
                                dispositivo depende do seu "tempo de vida" e das
                                configurações do seu navegador de internet). São
                                esses cookies que são utilizados nas visitas ao
                                site, mostrando os conteúdos mais relevantes e
                                personalizados de acordo com os seus interesses.
                              </li>
                            </ul>
                          </li>
                          <li>
                            <b>Cookies de Terceiros:</b>
                            <p>
                              A MPO SAÚDE utiliza cookies dos seguintes
                              fornecedores terceirizados, os quais estão
                              dispostos nas respectivas Políticas de Cookies de
                              Pacientes e Profissionais de Saúde. Recomendamos
                              que os Pacientes eProfissionais de Saúde consultem
                              as políticas de privacidade destes terceiros para
                              maiores informações a respeito da utilização dos
                              cookies.
                            </p>
                          </li>
                          <li>
                            <b>Acesso aos Cookies:</b>
                            <p>
                              O acesso aos cookies termina assim que o Paciente
                              ou Profissional de Saúde fecha seu navegador. É
                              dada ao Paciente ou Profissional de Saúde a
                              possibilidade de aceitar ou recusar os cookies.
                            </p>
                          </li>
                          <li>
                            <b>Opção sem Cookies:</b>
                            <p>
                              O Paciente ou Profissional de Saúde pode optar por
                              recusar o uso de Cookies. Se o Paciente ou
                              Profissional de Saúde optar por não os aceitar, o
                              seu acesso à maioria da informação disponível na
                              plataforma não será comprometido. Contudo, o
                              Paciente ou Profissional de Saúde poderá não
                              conseguir usar plenamente os serviços oferecidos.
                            </p>
                          </li>
                        </ol>
                      </MKTypography>
                    </li>
                    <li>
                      <h3>Direitos dos Titulares</h3>
                      <MKTypography
                        variant="body2"
                        color="text"
                        textAlign="justify"
                        ml={2}
                      >
                        <ol>
                          <li>
                            <b>Exercício dos Direitos dos Titulares:</b>
                            <p>
                              Os Pacientes e Profissionais de Saúde têm direitos
                              em relação aos dados pessoais sob posse da
                              Plataforma MPO SAÚDE, os quais poderão ser
                              exercidos por meio de contato aos administradores
                              da empresa através do e-mail
                              mpobeneficios@gmail.com, que será respondido em
                              horário comercial, de segunda-feira a sexta-feira,
                              das 9h às 18h do horário deBrasília, no prazo de
                              15 (quinze) dias e poderá ser enviado por e-mail
                              ou através de carta, de acordo com o previsto no
                              artigo 9o da LGPD, de modo a assegurar os
                              seguintes direitos:Direito de confirmação da
                              existência de tratamento: direito de solicitar a
                              confirmação da existência de tratamento dos seus
                              dados pessoais através deinformações claras sobre
                              a origem dos dados, a inexistência de registro, os
                              critérios utilizados e a finalidade do tratamento.
                            </p>
                            <ul>
                              <li>
                                Direito de acesso: direito de ser informado e
                                solicitar acesso aos dados pessoais tratados
                                pela Plataforma MPO SAÚDE.
                              </li>
                              <li>
                                Direito de Retificação de Dados Pessoais:
                                direito de solicitar que a Plataforma MPO SAÚDE
                                altere ou atualize os seus dados pessoaisquando
                                os mesmos estiverem incorretos ou incompletos.
                              </li>
                              <li>
                                Exclusão. O Paciente ou Profissional de Saúde
                                tem direito de solicitar a exclusão dos seus
                                dados pessoais armazenados na MPO SAÚDE, a
                                qualquer momento, salvo nos casos de obrigação
                                legal ou decisão judicial,de acordo com o
                                disposto nos artigos 18, XVI, e 16 da LGPD. Em
                                conformidade com o art. 6o da Lei do Prontuário
                                Eletrônico (Lei no 13.787/2018), assim como em
                                conformidade com o art. 8o da Resolução no
                                1.821/2007 do Conselho Federal de Medicina, a
                                MPO SAÚDE e o MÉDICO mantém os prontuários
                                armazenados, de forma segura, pelo prazo de 20
                                (vinte) anos para que possa cumprir a LGPD, nas
                                hipóteses destacadas acima.
                              </li>
                              <li>
                                Qualidade dos Dados. É responsabilidade do
                                titular manter os dados pessoais atualizados,
                                sendo certo que, no caso de incorreção, a MPO
                                SAÚDE poderá atualizá-las de acordo com a
                                solicitação.
                              </li>
                              <li>
                                Direito de Oposição: direito de se opor, a
                                qualquer momento, (i) ao processamento de seus
                                dados pessoais por motivos relacionados a sua
                                situação particular e (ii) à manipulação dos
                                seus dados pessoais para fins demarketing
                                direto.
                              </li>
                              <li>
                                Portabilidade:direitodesolicitarumacópiadosseusdadospessoaisemformatoeletrônicoe/outransmitir
                                os referidos dados pessoais para utilização no
                                serviço de terceiros;
                              </li>
                              <li>
                                Decisões automatizadas: direito de não se
                                submeter a decisões tomadas de forma unicamente
                                automatizadas, incluindo no que diz respeito à
                                definição de perfis, caso a decisão exerça
                                efeito jurídico sobre o Paciente e Profissional
                                de Saúde, ou produza um efeito igualmente
                                significativo.
                              </li>
                              <li>
                                Anonimização e/ou bloqueio: direito de solicitar
                                (i) a anonimização dos seusdados pessoais, assim
                                eles não poderão mais ser relacionados a Você e,
                                portanto, deixem de ser dados pessoais; (ii) o
                                bloqueio dos seus dadospessoais
                              </li>
                              <li>
                                Direito de restrição: direito de solicitar que a
                                Plataforma MPO SAÚDE deixe, temporária ou
                                permanentemente, de processar todos ou alguns
                                dos seus dados pessoais.
                              </li>
                            </ul>
                          </li>
                          <li>
                            <b>Exceção à Solicitação de Exclusão:</b>
                            <p>
                              Os Pacientes e os Profissionais de Saúde têm
                              direito de solicitar a exclusão dos seus dados
                              pessoais armazenados na Plataforma MPO SAÚDE, a
                              qualquer momento, salvo nos casos de obrigação
                              legal ou decisão judicial, de acordo com o
                              disposto nos artigos 18, XVI, e 16 da LGPD.
                            </p>
                          </li>
                          <li>
                            <b>Incorreção de Informações:</b>
                            <p>
                              É responsabilidade do titular manter
                              suasinformações atualizadas, sendo certo que, no
                              caso de incorreção, a Plataforma MPO SAÚDE poderá
                              atualizá-las ou excluí-las, excetuando-se os casos
                              denecessidade de manutenção para fins comerciais
                              legítimos ou jurídicos.
                            </p>
                          </li>
                          <li>
                            <b>
                              Medidas de Segurança da Informação e Sigilo
                              Profissional:
                            </b>
                            <p>
                              A MPO SAÚDE toma as medidas de segurança da
                              informação necessárias para proteger os dados
                              pessoais e sensíveis dos Pacientes e Profissionais
                              de Saúde e tutelar o sigilo profissional
                              respectivo, conforme orientado pelos conselhos
                              profissionais, com a utilização de criptografia
                              para armazenamento dos prontuários e restrição de
                              acesso aos Profissionais de Saúde assistentes, com
                              o objetivo de proteger os prontuários contra a
                              perda, utilização indevida, acesso indevido,
                              divulgação, alteração ou destruição não
                              autorizados.
                            </p>
                          </li>
                          <li>
                            <b>Proteção de Senhas:</b>
                            <p>
                              O Paciente e o Profissional de Saúde são
                              igualmente responsáveis por tomar medidas cabíveis
                              destinadas a proteger as suas senhas, nomes de
                              Paciente e outros recursos de acesso especiais ao
                              cadastro pessoal na MPO SAÚDE.
                            </p>
                          </li>
                        </ol>
                      </MKTypography>
                    </li>
                    <li>
                      <h3>
                        Notificação de Modificações na Política de Privacidade
                      </h3>
                      <MKTypography
                        variant="body2"
                        color="text"
                        textAlign="justify"
                        ml={2}
                      >
                        <ol>
                          <li>
                            <b>Alterações na Política de Privacidade:</b>
                            <p>
                              A MPO SAÚDE pode alterar a Política de Privacidade
                              da Plataforma periodicamente. Se a MPO SAÚDE
                              realizar mudanças na forma como utiliza as
                              informações pessoais, notificará os Pacientes e
                              Profissionais de Saúde através de anúncio na
                              plataforma ou mediante envio de e-mail ao Paciente
                              ou Profissional de Saúde. Neste caso, o Paciente
                              ou Profissional de Saúde se torna responsável por
                              ler tais avisos legais referentes às mudanças, que
                              passarão a produzir efeitos a partir da data da
                              publicação da atualização da Política de
                              Privacidade.
                            </p>
                          </li>
                          <li>
                            <b>Aceitação Tácita:</b>
                            <p>
                              O uso contínuo, pelo Paciente ou Profissional de
                              Saúde, dos serviços após a data da entrada em
                              vigor de qualquer alteração realizada e
                              previamente notificada, constitui aceitação da
                              Política de Privacidade alterada. Por esta razão,
                              a MPO SAÚDE encoraja os Pacientes e Profissionais
                              de Saúde a reverem esta Política de Privacidade
                              sempre que forem utilizar os serviços.
                            </p>
                          </li>
                        </ol>
                      </MKTypography>
                    </li>
                    <li>
                      <h3>Comunicação</h3>
                      <MKTypography
                        variant="body2"
                        color="text"
                        textAlign="justify"
                        ml={2}
                      >
                        <ol>
                          <li>
                            <b>Envio de Comunicações:</b>
                            <p>
                              O Paciente ou Profissional de Saúde, ao se
                              cadastrar, concorda que a MPO SAÚDE envie para o
                              seu e-mail cadastrado notificações e comunicações,
                              novidades sobre os serviços e informações
                              importantes que necessitem da sua atenção.
                            </p>
                          </li>
                          <li>
                            <b>Mensagens Publicitárias:</b>
                            <p>
                              Caso tenha consentido com a finalidade de
                              utilização dos dados pessoais para o envio de
                              mensagens publicitárias, notícias e novas
                              funcionalidades das plataformas, os Pacientes e
                              Profissionais de Saúde poderão receber mensagens
                              publicitárias da MPO SAÚDE.
                            </p>
                          </li>
                          <li>
                            <b>Opção por Não Recebimento:</b>
                            <p>
                              Ao receber um e-mail em nome da plataforma, haverá
                              a possibilidade de optar por não mais recebê-los,
                              utilizando da opção opt-out ou através de
                              solicitação por e-mail.
                            </p>
                          </li>
                          <li>
                            <b>Política AntiSpam:</b>
                            <p>
                              A plataforma toma os cuidados necessários para
                              evitar o envio não solicitado de e-mails.
                            </p>
                          </li>
                          <li>
                            <b>Confidencialidade:</b>
                            <p>
                              É assegurado o mais alto grau de confidencialidade
                              no tratamento de dados como a lista de telefones e
                              e-mails durante as tarefas regulares de
                              administração da MPO SAÚDE.
                            </p>
                          </li>
                        </ol>
                      </MKTypography>
                    </li>
                    <li>
                      <h3>Proibições</h3>
                      <MKTypography
                        variant="body2"
                        color="text"
                        textAlign="justify"
                        ml={2}
                      >
                        <ol>
                          <li>
                            <b>Remoção:</b>
                            <p>
                              A MPO SAÚDE se reserva o direito de negar ou
                              remover qualquer conexão que contenha informações
                              incorretas ou apelos não substanciados, ou que
                              estejam em conflito com a missão, política e
                              finalidade do da Plataforma MPO SAÚDE.
                            </p>
                          </li>
                        </ol>
                      </MKTypography>
                    </li>
                    <li>
                      <h3>Segurança da Informação e Uso de Dados Pessoais</h3>
                      <MKTypography
                        variant="body2"
                        color="text"
                        textAlign="justify"
                        ml={2}
                      >
                        <ol>
                          <li>
                            <b>Armazenamento:</b>
                            <p>
                              Os dados pessoais de sua conta e todas as
                              informações da plataforma são armazenados e
                              trafegam de forma segura, sendo que apenas os
                              colaboradores autorizados pela MPO SAÚDE podem ter
                              acesso aos seus dados pessoais e sensíveis,
                              estando obrigatoriamente sujeitos aos deveres de
                              confidencialidade, sigilo profissional e rigoroso
                              respeito à privacidade, nos termos desta Política
                              e da regulação aplicável.
                            </p>
                          </li>
                          <li>
                            <b>Segurança da Informação:</b>
                            <p>
                              Todas as transações da MPO SAÚDE são
                              criptografadas, sujeitas a backup de dados,
                              ferramentas de monitoramento, políticas de
                              segurança, controles de acesso para colaboradores,
                              com softwares de segurança atualizados.
                            </p>
                          </li>
                          <li>
                            <b>Transferência Internacional de Dados:</b>
                            <p>
                              Os dados pessoais armazenados pela Plataforma MPO
                              SAÚDE estão sujeitos às regras para transferência
                              internacional de dados estabelecidas pelo art. 33
                              da Lei Geral de Proteção de Dados e deve ser
                              realizada apenas para países que proporcionem
                              proteção semelhante à existente no Brasil.
                            </p>
                          </li>
                          <li>
                            <b>Notificação:</b>
                            <p>
                              Se a MPO SAÚDE tomar conhecimento de qualquer
                              violação à segurança própria ou a empresas de
                              hospedagem, incluindo invasões, vazamentos de
                              dados ou qualquer outro incidente de segurança da
                              informação, iremos notificar a Autoridade Nacional
                              de Proteção de Dados (ANPD), os Pacientese
                              Profissionais de Saúde eventualmente afetados
                              acerca dessa violação e iremos fornecer o máximo
                              de detalhes referente à natureza, extensão da
                              violação e os dados comprometidos, em prazo
                              razoável, de acordo com o disposto nos artigos 48,
                              § 1o, da LGPD.
                            </p>
                          </li>
                          <li>
                            <b>Encarregado de Proteção de Dados:</b>
                            <p>
                              O Encarregado de Proteção de Dados da MPO SAÚDE
                              poderá ser contatado através do e-mail
                              mpobeneficios@gmail.com ou de carta direcionada
                              para o endereço da MPO SAÚDE, nos termos do artigo
                              41 da LGPD.
                            </p>
                          </li>
                        </ol>
                      </MKTypography>
                    </li>
                    <li>
                      <h3>Disposições Gerais</h3>
                      <MKTypography
                        variant="body2"
                        color="text"
                        textAlign="justify"
                        ml={2}
                      >
                        <ol>
                          <li>
                            <b>Responsabilidade do Médico:</b>
                            <p>
                              É de inteira responsabilidade do médico o envio
                              correto dos documentos para os paciente, incluindo
                              relatórios, laudos, solicitação de exames e
                              receitas.
                            </p>
                          </li>
                          <li>
                            <b>
                              Responsabilidade do Paciente e Profissional de
                              Saúde:
                            </b>
                            <p>
                              Os Pacientes e Profissionais de Saúde serão
                              responsáveis por indenizar a MPO SAÚDE por todos
                              os custos e danos que este possa incorrer, como
                              resultado da violação desta Política de
                              Privacidade por si provocados.
                            </p>
                          </li>
                          <li>
                            <b>Cooperação com Autoridades:</b>
                            <p>
                              A MPO SAÚDE coopera plenamente com quaisquer
                              autoridades ou tribunais que venham a solicitar a
                              divulgação da identidade ou localização de
                              qualquer pessoa que tenha inserido qualquer
                              material na plataforma que violem as cláusulas
                              dispostas nesta Política.
                            </p>
                          </li>
                          <li>
                            <b>Limitação de Responsabilidade:</b>
                            <p>
                              Esta Política de Privacidade aborda apenas o uso e
                              divulgação de informações coletadas pela
                              plataforma da MPO SAÚDE. Seos Pacientes e
                              Profissionais de Saúde divulgarem suas informações
                              a websites de terceiros, regras diferentes podem
                              ser aplicadas à utilização das suas informações.
                            </p>
                          </li>
                        </ol>
                      </MKTypography>
                    </li>
                    <li>
                      <h3>Termo de Consentimento do Paciente</h3>
                      <MKTypography
                        variant="body2"
                        color="text"
                        textAlign="justify"
                        ml={2}
                      >
                        <ol>
                          <li>
                            <b>
                              O presente termo de consentimento do paciente é
                              composto de descrição técnica das etapas de
                              atendimento do paciente e sua concordância, bem
                              como das normas de segurança para uso da Tele
                              consulta na Telemedicina.
                            </b>
                            <MKTypography
                              variant="body2"
                              color="text"
                              textAlign="justify"
                              ml={2}
                            >
                              <ul>
                                <li>
                                  Observei atentamente ao currículo de cada
                                  Médico e sua formação para melhor lhe atender.
                                  Declaro de minha livre escolha que a
                                  realização desta consulta será feita por
                                  videoconferência.
                                </li>
                                <li>
                                  Entendo que a teleconsulta é individual e
                                  intransferível, e permitirá que eu apresente
                                  ao meu profissional da saúde: sinais e
                                  sintomas, resultados de exames complementares,
                                  medicamentos que eu esteja tomando (prescritos
                                  por ele ou por outro profissional), para que
                                  ele possa traçar uma hipótese diagnóstica e
                                  sugerir um plano de tratamento.
                                </li>
                                <li>
                                  Estou ciente que pode haver a indicação,
                                  dependendo do caso clínico, de atendimento
                                  pessoal em consultório ou posto de saúde.
                                </li>
                                <li>
                                  Entendo, que poderei usar este canal para
                                  solicitar orientações para minha saúde de
                                  forma preventiva, para evitar doenças.
                                </li>
                                <li>
                                  Entendo que as imagens do vídeo e o áudio da
                                  teleconsulta poderão ser gravados, ou apenas
                                  as anotações do profissional de saúde;
                                </li>
                                <li>
                                  Estou ciente que quem encerra a teleconsulta
                                  sou eu (paciente), limitado ao tempo
                                  contratado e disponibilizado na contratação da
                                  consulta.
                                </li>
                                <li>
                                  A responsabilidade pelas condições técnicas de
                                  acesso para a teleconsulta por vídeo
                                  conferência é integralmente minha no que se
                                  refere a: equipamento, disponibilidade de
                                  internet pelo meu servidor, e funcionamento de
                                  vídeo e de áudio, sendo que me comprometo a
                                  testar todos esses aspectos antes do início da
                                  consulta e tão logo eu receba o link, para que
                                  tenha tempo hábil para efetuar quaisquer
                                  reparos ou ajustes necessários.
                                </li>
                                <li>
                                  Concordo e autorizo a prática da telemedicina
                                  a ser realizada pelo profissional de saúde, a
                                  fim de auxiliar-me na busca por maior
                                  cobertura assistencial mais ampla quanto aos
                                  cuidados com minha saúde, seja para
                                  estabelecer uma hipótese diagnóstica, um plano
                                  de tratamento, orientações gerais sobre
                                  medicamentos e alimentação, e o que mais se
                                  fizer necessário para minha saúde.
                                </li>
                                <li>
                                  Estou ciente que as receitas Brancas de
                                  controle especial são enviadas ao paciente em
                                  arquivo pdf e as demais (AZUL e AMARELA) via
                                  correios. Serão postadas no correio num prazo
                                  de até 5 dias úteis. A MPO não envia
                                  medicamentos, apenas receita Azul e Amarela
                                  por correios sem custo ao cliente via carta
                                  registrada. Estou ciente que, conforme
                                  diretrizes do conselho federal de Farmácia, a
                                  validade de receita controle especial Azul e
                                  amarela é de 30 dias e no máximo 60 dias para
                                  receitas brancas.
                                </li>
                                <li>
                                  Caso eu (paciente) opte pelo envio via sedex,
                                  estou ciente que arcarei com os custos de
                                  envio.
                                </li>
                                <li>
                                  Estou ciente que em caso de cancelamento o
                                  aviso deve ser dado antes da realização da
                                  consulta num prazo máximo de 1 hora. Em caso
                                  de ausência da consulta sem justificativa
                                  prévia de 1 hs, será cobrada o valor integral
                                  da mesma.
                                </li>
                                <li>
                                  Estou ciente que não terei retorno desta minha
                                  consulta, sendo ela única. Caso haja
                                  necessidade de outras consultas, pagarei de
                                  acordo com os preços estabelecidos no site
                                  mposaude.com.br.
                                </li>
                                <li>
                                  Estou ciente que não há retorno de consulta,
                                  sendo assim, cada consulta é única.
                                </li>
                                <li>
                                  Confirmo que recebi explicações, li,
                                  compreendi e concordo com os itens descritos
                                  neste Termo de Segurança e Privacidade, bom
                                  como as condições gerais da Teleconsulta na
                                  Telemedicina MPO SAÚDE.
                                </li>
                                <li>
                                  Estou ciente de que qualquer dúvida poderá ser
                                  esclarecida na seção de Central de Suporte, ou
                                  diretamente com o profissional de saúde que
                                  acompanha meu caso.
                                </li>
                                <li>
                                  Estou ciente e concordo que ao ser solicitado
                                  durante a consulta pelo médico ou pelo Call
                                  Center de um contato de emergência, deverei
                                  repassar imediatamente. Esse contato deverá
                                  ser de alguma pessoa próxima seja com grau de
                                  parentesco ou não, e que tenha acesso
                                  facilitado e rápido caso necessite falar ou
                                  entrar em contato pessoal comigo (paciente).
                                  Em caso do não compartilhamento, o médico
                                  poderá encerrar a consulta.
                                </li>
                              </ul>
                            </MKTypography>
                          </li>
                        </ol>
                      </MKTypography>
                    </li>
                    <li>
                      <h3>
                        Termo de Ciência Quanto à Limitações da Consulta Online
                      </h3>
                      <MKTypography
                        variant="body2"
                        color="text"
                        textAlign="justify"
                        ml={2}
                      >
                        <ol>
                          <li>
                            <b>Limitações:</b>
                            <p>
                              O profissional que lhe atender possui total
                              autonomia para determinar se é necessário uma
                              receita médica ou atestado. O Médico tem autonomia
                              para emitir ou não: laudos, receitas ou atestados.
                            </p>
                          </li>
                          <li>
                            <b>Atestados Médicos Emitidos:</b>
                            <p>
                              Os atestados médicos que podem ser emitidos por
                              teleconsulta, são:
                            </p>
                            <MKTypography
                                variant="body2"
                                color="text"
                                textAlign="justify"
                                ml={2}
                            >
                              <ul>
                                <li>
                                  Afastamento do trabalho por motivos de doença
                                  desde que o médico determine a necessidade;
                                </li>
                                <li>Atestado de comparecimento à consulta.</li>
                              </ul>
                            </MKTypography>
                          </li>
                          <li>
                            <b>Atestados Médicos Não Emitidos:</b>
                            <p>
                              Atestados médicos que NÃO SÃO EMITIDOS EM
                              TELECONSULTA por depender de análise física e/ou
                              exames complementares:
                            </p>
                            <MKTypography
                                variant="body2"
                                color="text"
                                textAlign="justify"
                                ml={2}
                            >
                              <ul>
                                <li>
                                  Para uso de piscina, por precisar de exame
                                  físico.
                                </li>
                                <li>
                                  Aptidão física, por necessitar de exame físico
                                  e teste funcional.
                                </li>
                                <li>
                                  Para prática de esportes, por necessitar de
                                  exame físico e/ou teste funcional.
                                </li>
                                <li>
                                  Atestar que o paciente é portador de
                                  deficiência física (exceto se apresentar
                                  atestado anterior);
                                </li>
                              </ul>
                            </MKTypography>
                          </li>
                          <li>
                            <b>Medicações e Receitas:</b>
                            <p>
                              As nossas receitas digitais, são enviadas via whatsapp e email pelo sistema da plataforma utilizada.
                            </p>
                            <p>
                              Solicitamos aos usuários e pacientes, que sempre confiram se as mesmas estão em seu nome (que se encontra no cabeçalho do documento), evitando assim qualquer transtorno.
                            </p>
                          </li>
                        </ol>
                      </MKTypography>
                    </li>
                    <li>
                      <h3>Ressarcimento</h3>
                      <MKTypography
                          variant="body2"
                          color="text"
                          textAlign="justify"
                          ml={2}
                      >
                        <ol>
                          <li>
                            <p>
                            Caso o Cliente desista da consulta por qualquer
                              razão, a MPO SAUDE poderá ressarcir em até 7
                              (sete) dias o valor cobrado. E o mesmo será
                              efetuado de Segunda-feira a sexta-feira das 08:30
                              as 17:30.
                            </p>
                          </li>
                        </ol>
                      </MKTypography>
                    </li>
                    <li>
                      <h3>
                        Normas de Segurança do Termos de Consentimento do
                        Paciente
                      </h3>
                      <MKTypography
                        variant="body2"
                        color="text"
                        textAlign="justify"
                        ml={2}
                      >
                        <ol>
                          <li>
                            <b>Inscrição no CRM:</b>
                            <p>
                              Se houve dúvidas sobre o médico que irá lhe
                              atender, busque a confirmação no site do Conselho
                              Federal de Medicina. Você poderá buscar pelo CRM
                              (informado pelo seu médico da telemedicina), nome,
                              cidade, especialidade. Isso lhe trará a
                              tranquilidade de que realmente você está falando
                              com um médico.
                            </p>
                          </li>
                          <li>
                            <b>RQE:</b>
                            <p>
                              Se o médico esta anunciando ser especialista, isso
                              também pode ser confirmado através do RQE
                              (Registro de Qualificação da Especialidade), que é
                              o registro do Título de Especialista que ele
                              matriculou no CRM do estado em que reside.
                              validando que ele realmente é especialista naquela
                              área que ele se anuncia como tal. Sugerimos que
                              verifique e escolha o médico conforme sua
                              necessidade.
                            </p>
                          </li>
                          <li>
                            <b>
                              Os honorários referentes à consulta por
                              telemedicina, atende os preceitos éticos do Código
                              de Ética Médica (Cap. I, item III) e a Portaria n.
                              467 do Ministério da Saúde.
                            </b>
                          </li>
                          <li>
                            <b>Legalidade da Telemedicina:</b>
                            <p>
                              A prática da Telemedicina é regulamentada pela
                              Portaria n. 467 do Ministério da Saúde e Resolução
                              CFM 1.643/2002. Respeitada as suas limitações, a
                              TELEMEDICINA é uma opção de consulta que pode ser
                              muito útil, principalmente em situações de
                              calamidade pública. Ademais, pode conferir uma
                              maior assistência para casos selecionados de
                              pacientes, respeitando as particularidades de cada
                              caso dentro de um bom senso e dos preceitos éticos
                              médicos. A responsabilidade do médico ao praticar
                              um ato médico por telemedicina deverá atender os
                              preceitos éticos do Código de Ética Médica e
                              Resoluções do CFM.
                            </p>
                          </li>
                          <li>
                            <b>
                              Declaração dos Termos de Consentimento do Paciente
                            </b>
                            <p>
                              Pelo presente termo, DECLARO que procurei o médico
                              acima por livre e espontânea vontade para fins de
                              realização de atendimento via Telemedicina e, para
                              tanto, declaro que, na condição de
                              paciente/responsável legal, obtive orientação e
                              esclarecimentos suficientes sobre o ato médico e
                              DECLARO ainda:
                            </p>
                            <MKTypography
                              variant="body2"
                              color="text"
                              textAlign="justify"
                              ml={2}
                            >
                              <ul>
                                <li>
                                  que tive explicação suficiente acerca do
                                  objetivo da Telemedicina estipulada na
                                  Portaria n. 467/2020 do Ministério da Saúde,
                                  bem como acerca dos seus limites, e que ela
                                  contempla o atendimento pré-clínico, de
                                  suporte assistencial, de consulta,
                                  monitoramento e diagnóstico, por meio de
                                  tecnologia da informação;
                                </li>
                                <li>
                                  que compreendi que as ações de Telemedicina de
                                  interação à distância preconizadas na Portaria
                                  n. 467/2020 do Ministério da Saúde foram
                                  instituídas em razão de situação emergencial
                                  de calamidade pública provocada pelo COVID-19,
                                  e que em hipótese nenhuma, este tipo de
                                  atendimento substitui uma consulta presencial;
                                </li>
                                <li>
                                  que fui cientificado e concordei que em caso
                                  de persistirem os sintomas que me levaram a
                                  procurar ações de Telemedicina, ou no caso de
                                  mal-estar, febre, dificuldade respiratória,
                                  palpitação cardíaca, dentre outros, deverei
                                  procurar imediatamente atendimento em Pronto
                                  Atendimento em unidade hospitalar;
                                </li>
                                <li>
                                  que o Médico acima identificado sugeriu e
                                  concordei que o ato via Telemedicina se
                                  realize por meio de uma plataforma de
                                  Telemedicina, bem como declaro que estipulamos
                                  formas alternativas de interação em tempo real
                                  e de comunicação imediata caso o instrumento
                                  tecnológico originariamente previsto para ser
                                  utilizado não funcionar.
                                </li>
                                <li>
                                  que é de única e inteira reponsabilidade do
                                  médico que realizará a minha consulta o envio
                                  correto dos documentos quando necessários,
                                  incluindo relatórios, laudos, solicitação de
                                  exames e receitas.
                                </li>
                                <li>
                                  que o envio deste termo não significa
                                  necessariamente atendimento imediato. O dia e
                                  horário para o atendimento por telemedicina
                                  deverão ser acordada entre as partes conforme
                                  disponibilidade de agenda do médico e meios de
                                  comunicação disponíveis.
                                </li>
                                <li>
                                  que estou ciente que ao ser solicitado durante
                                  a consulta pelo médico ou pelo Call Center de
                                  um contato de emergência, deverei repassar
                                  imediatamente. Esse contato deverá ser de
                                  alguma pessoa próxima seja com grau de
                                  parentesco ou não, e que tenha acesso
                                  facilitado e rápido caso necessite falar ou
                                  entrar em contato pessoal comigo (paciente).
                                  Em caso do não compartilhamento, o médico
                                  poderá encerrar a consulta e dada como
                                  encerrado o atendimento. Essa situação serve
                                  para os casos em que o médico avalia que há
                                  riscos de morte por conta do meu estado
                                  emocional e precisa ser rápido a intervenção
                                  presencial de alguém.
                                </li>
                              </ul>
                            </MKTypography>
                          </li>
                        </ol>
                      </MKTypography>
                    </li>
                  </ol>
                  <p>
                    <b>
                      Por ser a expressão da verdade, na presente data, firmo o
                      presente instrumento através de assinatura digital.
                    </b>
                  </p>
                </MKTypography>
              </MKBox>
            </MKBox>
          </Card>
        </MKBox>
        <MKBox pt={1} mt={1}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>
      </Container>
    </>
  );
}
