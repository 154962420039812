/*
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect, useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Otis Kit PRO examples
import Menu from "components/Menu";
import DefaultFooter from "components/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// images
import LogoMPO from "assets/images/logo_horizontal.png";
import { useParams } from "react-router-dom";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/pt-br";

import MKButton from "components/MKButton";
import { api } from "../../lib/axios";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale("pt-br");

function Confirmation() {
  const { id } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const params = {
    order_id: urlParams.get("order_id"),
    valor: urlParams.get("valor"),
  }

  const authenticatedUser = JSON.parse(localStorage.getItem("user")) ?? false;
  const routeIndex = authenticatedUser.type ?? "public";

  const [appointment, setAppointment] = useState({});
  const [userInfos, setUserInfos] = useState({});

  const getSchedule = async (scheduleId) => {
    try {
      const response = await api.get(`/schedules/${scheduleId}`);
      return response.data;
    } catch (err) {
      window.location.replace("/");
    }
    return null;
  };

  const getUserInfos = async () => {
    try {
      const { data } = await api.get(`/users/${authenticatedUser.id}`);
      setUserInfos(data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    async function getInfos() {
      if (Object.keys(appointment).length === 0) {
        const userAppointment = await getSchedule(id);
        if (!userAppointment) {
          window.location.replace("/");
        }
        if (userAppointment.status === "PENDENTE") {
          window.location.replace(`/payment/${userAppointment.id}`);
        }
        setAppointment(userAppointment);
        await getUserInfos();
      }
    }
    getInfos();
  }, []);

  return (
    <>
      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />
      <Container>
        <MKBox component="section" pt={20} pb={12}>
          <Container>
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Card>
                  <MKBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    mx={2}
                    mt={-3}
                    p={1}
                    mb={1}
                    textAlign="center"
                  >
                    <MKTypography variant="body2" color="white" mt={0}>
                      Confirmação
                    </MKTypography>
                  </MKBox>
                  {Object.keys(appointment).length > 0 &&
                  appointment.status === "AGENDADO" ? (
                    <MKBox
                      px={6}
                      mt={5}
                      sx={{
                        paddingBottom: "50px",
                      }}
                    >
                      <MKTypography variant="body2">
                        Parabéns pela decisão!
                      </MKTypography>
                      <MKTypography variant="body2">
                        Seu pedido de agendamento foi confirmado e em breve
                        enviaremos mensagens com os detalhes do agendamento.
                      </MKTypography>
                      <MKTypography variant="body2">
                        <b>Código do Agendamento:</b> {appointment.id}
                      </MKTypography>
                      {params?.order_id  && params?.valor &&  (
                          <>
                            <MKTypography variant="body2">
                              <b>ID do Pagamento:</b> {params.order_id}
                            </MKTypography>
                            <MKTypography variant="body2">
                              <b>Valor:</b> {parseFloat(params.valor).toLocaleString('pt-BR', {
                                  style: 'currency',
                                  currency: 'BRL',
                                })}
                            </MKTypography>
                          </>
                      )}
                      <MKTypography variant="body2">
                        <b>Status:</b>{" "}
                        {appointment.status === "PROCESSANDO"
                          ? "Aguardando Pagamento"
                          : "Confirmado"}
                      </MKTypography>
                      <MKTypography variant="body2">
                        Conte sempre conosco!
                      </MKTypography>
                      <MKTypography variant="body2">
                        Equipe do Meu Psiquiatra Online.
                      </MKTypography>
                    </MKBox>
                  ) : (
                    <MKBox
                      sx={{
                        height: "500px",
                        textAlign: "center",
                        padding: "200px",
                      }}
                    >
                      {appointment?.status === "FINALIZADO" ||
                      appointment?.status === "AVALIAÇÃO" ? (
                        <MKTypography>
                          Este agendamento já foi finalizado.
                        </MKTypography>
                      ) : (
                        <MKTypography>Processando...</MKTypography>
                      )}
                    </MKBox>
                  )}
                  {userInfos?.birth_date !== "" &&
                    dayjs(userInfos.birth_date).format("YYYY-MM-DD") ===
                      "1924-10-08" && (
                      <MKBox
                        sx={{
                          textAlign: "center",
                          padding: "20px",
                        }}
                      >
                        <MKTypography sx={{ color: "red", fontWeight: "700" }}>
                          Sua data de nascimento está incorreta, por favor
                          altere na área do usuário.
                        </MKTypography>
                        <MKTypography sx={{ color: "red", fontWeight: "700" }}>
                          Precisamos dela para preencher as informações no seu
                          prontuário médico.
                        </MKTypography>
                        <MKButton
                          href="/user-area"
                          variant="contained"
                          color="info"
                          sx={{ marginTop: "20px" }}
                        >
                          Acessar Área do Usuário
                        </MKButton>
                      </MKBox>
                    )}
                </Card>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
        <MKBox pt={1} mt={1}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>
      </Container>
    </>
  );
}

export default Confirmation;
